<!--
 * @Description: 
 * @version: 1.0
 * @Author: Sunwx
 * @Date: 2021-10-26 09:13:31
 * @LastEditors: Sunwx
 * @LastEditTime: 2021-10-26 14:14:56
-->
<template>
    <div class="sys-report-class">
        <el-row class="sys-report-row">
            <el-col :span="11">
                <div style="width: 200px;font-size: 20px;">年生态流量统计</div>
            </el-col>
            <el-col :span="13" class="row_13" >
                <el-form label-width="80px" :inline='true'>
                    <el-form-item>
                        <el-input placeholder="请输入内容" v-model="selectAddress" class="input-with-select" size="mini">
                            <el-select v-model="selectOptions" slot="prepend" placeholder="请选择" size="mini">
                                <el-option label="广东省信宜市" value="1"></el-option>
                            </el-select>
                            <el-button slot="append" icon="el-icon-search" size="mini"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="query" size="mini">查询</el-button>
                        <el-button type="primary" @click="exprotExcel" size="mini">导出</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData" style="width: 100%" @row-click="rowClick" ref="tableList" border>
            <el-table-column label="水电站名称" prop="name"></el-table-column>
            <el-table-column label="区域" prop="area"></el-table-column>
            <el-table-column label="核准生态流量" prop="stlx"></el-table-column>
            <el-table-column label="缺失小时数" prop="qs"></el-table-column>
            <el-table-column label="缺失率" prop="qstime"></el-table-column>
            <el-table-column label="未达标小时数" prop="wdb"></el-table-column>
            <el-table-column label="未达标率" prop="wdbtime"></el-table-column>
            <el-table-column label="电价补贴标准" prop="djbtl"></el-table-column>
        </el-table>
        <div class="block nextpage">
            <el-pagination @size-change="handleSizeChange" 
                @current-change="handleCurrentChange" 
                :current-page="currentPage" 
                :page-sizes="[8, 16, 32, 64]" 
                :page-size="pagesize" 
                layout="total, sizes, prev, pager, next, jumper" 
                :total="tableData.length">
            </el-pagination>
        </div>

        <el-dialog v-dialogDrag :title="dialog.title" :close-on-click-modal="false" 
            :visible.sync="dialog.flag" width="60%">
            <el-tabs style="height: 600px;overflow-y: scroll;" type="border-card" v-model="dialog.active" >
                <el-tab-pane label="实时数据" name="realtime">
                    <real-time v-if="dialog.active == 'realtime'"></real-time>
                </el-tab-pane>
                <el-tab-pane label="历史免考" name="historyOmit">
                    <history-omit v-if="dialog.active == 'historyOmit'"></history-omit>
                </el-tab-pane>
                <el-tab-pane label="历史流量" name="historyTraffic">
                    <history-traffic v-if="dialog.active == 'historyTraffic'"></history-traffic>
                </el-tab-pane>
                <el-tab-pane label="历史图像" name="historyImage">
                    <history-image v-if="dialog.active == 'historyImage'"></history-image>
                </el-tab-pane>
                <el-tab-pane label="基础信息" name="basicInfo">
                    <basic-info v-if="dialog.active == 'basicInfo'"></basic-info>
                </el-tab-pane>
                <el-tab-pane label="工程图库" name="projectImage">
					<project-image v-if="dialog.active == 'projectImage'"></project-image>
				</el-tab-pane>
            </el-tabs>
        </el-dialog>
    </div>
</template>
<script>
const realTime= ()=> import ("@/views/second/components/realtime")
const historyOmit= ()=> import ("@/views/second/components/historyOmit")
const historyTraffic= ()=> import ("@/views/second/components/historyTraffic")
const basicInfo= ()=> import ("@/views/second/components/basicInfo")
const historyImage= ()=> import ("@/views/second/components/historyImage")
const projectImage= ()=> import ("@/views/second/components/projectImage")
export default {
    data() {
        return {
            tableData: [
                {name: '大成长坑水电站', area: '信宜市', stlx: '15000', qs: '2343.00', qstime: '100', wdb: '100', wdbtime: '100 ', djbtl: '-2'},
                {name: '大成上坪河水电站', area: '信宜市', stlx: '15000', qs: '2343.00', qstime: '100', wdb: '100', wdbtime: '100 ', djbtl: '-2'},
                {name: '大成斯茅坪水电站', area: '信宜市', stlx: '15000', qs: '2343.00', qstime: '100', wdb: '100', wdbtime: '100 ', djbtl: '-2'},
                {name: '大成石景岭水电站', area: '信宜市', stlx: '15000', qs: '2343.00', qstime: '100', wdb: '100', wdbtime: '100 ', djbtl: '-2'},
                {name: '大成亚雀垌水电站', area: '信宜市', stlx: '15000', qs: '2343.00', qstime: '100', wdb: '100', wdbtime: '100 ', djbtl: '-2'},
                {name: '平塘兴源水电站', area: '信宜市', stlx: '15000', qs: '2343.00', qstime: '100', wdb: '100', wdbtime: '100 ', djbtl: '-2'},
                {name: '屏丽水电站', area: '信宜市', stlx: '15000', qs: '2343.00', qstime: '100', wdb: '100', wdbtime: '100 ', djbtl: '-2'},
                {name: '湖洋水电站', area: '信宜市', stlx: '15000', qs: '2343.00', qstime: '100', wdb: '100', wdbtime: '100 ', djbtl: '-2'},
            ],
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
            date: '',
            selectAddress: '',
            selectOptions: '',
            dialog: {
                flag: false,
                title: '',
                active: 'realtime',
            },
        };
    },
    components: {
        realTime,
        historyOmit,
        historyTraffic,
        basicInfo,
        historyImage,
		projectImage
    },
    created() {
    },
    watch: {
        
    },
    methods: {
        /**
         * @description: 表格行点击事件
         * @param {*} row
         * @return {*}
         * @author: Sunwx
         */        
        rowClick(row) {
            this.dialog.flag = true;
            this.dialog.title = row.name;
        },
        /**
         * @description: 切换页数
         * @param {*} val
         * @return {*}
         * @author: Sunwx
         */        
        handleSizeChange(val) {
            this.pagesize = val;
        },
        /**
         * @description: 切换页码
         * @param {*} val
         * @return {*}
         * @author: Sunwx
         */        
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        /**
         * @description: 查询
         * @param {*}
         * @return {*}
         * @author: Sunwx
         */        
        query(){
            alert('查询');
        },
        /**
         * @description: 导出
         * @param {*}
         * @return {*}
         * @author: Sunwx
         */        
        exprotExcel(){
            alert('导出');
        }
    }
}
</script>
<style>
.sys-report-class{
    width: 100%;
}
.el-select .el-input {
    width: 130px;
}
.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
.el-form-item{
    margin-bottom: 0;
}
.sys-report-row{
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin-bottom: 20px;
}
.el-form-item__content{
    line-height:0;
}
.el-form{
    float: right;
}
</style>

